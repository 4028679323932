import { ContentData } from '@rtl/api'
import { DOMParser } from '@xmldom/xmldom'

export const rssParser = (docString: string): ContentData[] => {
  enum SELECTORS {
    title = 'title',
    media = 'media:content',
    item = 'item',
    url = 'link',
    desc = 'description'
  }

  const result: ContentData[] = []
  let doc: Document | null = null

  try {
    doc = new DOMParser().parseFromString(docString, "text/xml")
  } catch (err) {
    console.error(err)
    return result
  }

  if (doc) {
    const items = doc.getElementsByTagName(SELECTORS.item)
    const itemsList = Array.from(items)
    itemsList.forEach((item, index) => { 
      const title = item.getElementsByTagName(SELECTORS.title)[0]?.textContent
      const link = item.getElementsByTagName(SELECTORS.url)[0]?.textContent
      const media = item.getElementsByTagName(SELECTORS.media)[0]?.getAttribute('url') ?? item.getElementsByTagName('enclosure')[0]?.getAttribute('url')
      const desc = item.getElementsByTagName(SELECTORS.desc)[0]?.textContent

      const itemData: ContentData = {
        id: String(index),
        title: title ?? '',
        outerUrl: link ?? '',
        lead: desc ?? '',
        leadImageUrls: {
          leadImageUrl169: media ?? ''
        }
      }
      result.push(itemData)
    })
  }

  return result
}