import { reactive } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import { VoteItem } from "@/api";
import { Vote } from "@/models";
import { VoteListPayload, VOTE_NAMESPACE, VOTE_ACTION } from "@/store";
import { Fetcher } from "@/utils";

@Options({})
export default class VoteListFetcher extends Vue {
  voteListRefreshed = false;
  voteListFetcherInstance: Fetcher<
    VoteListPayload,
    { data: Array<VoteItem> }
  > | null = null;

  get votes() {
    if (this.voteListFetcher.result?.data) {
      return this.voteListFetcher.result.data.map((data) => {
        return new Vote(data);
      });
    }
  }

  get voteListFetcher() {
    if (!this.voteListFetcherInstance) {
      this.voteListFetcherInstance = reactive(
        new Fetcher((payload: VoteListPayload) => {
          return this.$store.dispatch(
            `${VOTE_NAMESPACE}/${VOTE_ACTION.FETCH_LIST}`,
            payload
          );
        })
      );
    }

    return this.voteListFetcherInstance;
  }

  get voteListPayload() {
    return {};
  }

  @Watch("voteListPayload", { deep: true })
  async handleVoteList() {
    return this.fetchVoteList();
  }

  async fetchVoteList(cached = false) {
    return await this.voteListFetcher.run({
      data: this.voteListPayload,
      cached,
    });
  }

  async refreshVoteList() {
    await this.fetchVoteList(false);

    this.voteListRefreshed = true;
    setTimeout(() => {
      this.voteListRefreshed = false;
    }, 1000);
  }

  async visibilityVoteListHandler() {
    if (!document?.hidden) {
      await this.fetchVoteList(false);
    }
  }

  serverPrefetch() {
    return this.fetchVoteList();
  }

  beforeMount() {
    return this.fetchVoteList();
  }

  mounted() {
    document?.addEventListener("visibilitychange", () => {
      this.visibilityVoteListHandler();
    });
  }

  beforeUnmount() {
    document?.removeEventListener("visibilitychange", () => {
      this.visibilityVoteListHandler();
    });
  }
}
