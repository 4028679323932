export type FetcherCommand<P, R extends { data: unknown }> = (
  payload: P
) => Promise<R>;

export class Fetcher<P, R extends { data: unknown }> {
  command!: FetcherCommand<P, R>;
  result: R | null = null;
  error: unknown | null = null;
  loading = false;
  loaded = false;

  constructor(command: FetcherCommand<P, R>) {
    this.command = command;
  }

  async run(payload: P): Promise<R | null> {
    this.loading = true;
    this.error = null;

    try {
      this.result = await this.command(payload);
      return this.result;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
      this.loaded = true;
    }

    if (this.error) {
      throw this.error;
    }

    return null;
  }
}
