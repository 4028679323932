import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "tv-show-hero__wrapper" }
const _hoisted_2 = { class: "tv-show-hero__content" }
const _hoisted_3 = { class: "tv-show-hero__title" }
const _hoisted_4 = {
  key: 0,
  class: "tv-show-hero__text"
}
const _hoisted_5 = { class: "tv-show-hero__bottom" }
const _hoisted_6 = { class: "tv-show-hero__social" }
const _hoisted_7 = {
  key: 2,
  class: "tv-show-hero__social-button-wrapper"
}
const _hoisted_8 = { class: "tv-show-hero__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!
  const _component_tv_show_hero_nav = _resolveComponent("tv-show-hero-nav")!
  const _component_facebook = _resolveComponent("facebook")!
  const _component_instagram = _resolveComponent("instagram")!
  const _component_tiktok = _resolveComponent("tiktok")!
  const _component_twitter = _resolveComponent("twitter")!
  const _component_social_media_links = _resolveComponent("social-media-links")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_openBlock(), _createBlock(_component_widget_block, {
    class: "tv-show-hero",
    "show-title": false,
    "show-divider": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Image, {
          "image-url": _ctx.coverImgUrl,
          alt: _ctx.title,
          class: "tv-show-hero__cover"
        }, null, 8, ["image-url", "alt"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.logoUrl)
            ? (_openBlock(), _createBlock(_component_Image, {
                key: 0,
                "image-url": _ctx.logoUrl,
                alt: _ctx.title,
                class: "tv-show-hero__logo"
              }, null, 8, ["image-url", "alt"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
            (_ctx.info)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.info), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.ctaBtnLabel && _ctx.ctaBtnUrl)
          ? (_openBlock(), _createBlock(_component_nav_link, {
              key: 0,
              to: _ctx.ctaBtnUrl,
              class: "tv-show-hero__nav-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.ctaBtnLabel), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.menu?.length)
          ? (_openBlock(), _createBlock(_component_tv_show_hero_nav, {
              key: 1,
              "no-padding-left": !_ctx.ctaBtnUrl,
              "menu-items": _ctx.menu
            }, null, 8, ["no-padding-left", "menu-items"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_social_media_links, null, {
            default: _withCtx(({ facebook, instagram, tiktok, twitter }) => [
              facebook
                ? (_openBlock(), _createBlock(_component_nav_link, {
                    key: 0,
                    to: facebook,
                    target: "_blank",
                    class: "tv-show-hero__social-icon tv-show-hero__social-icon--facebook"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_facebook)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              instagram
                ? (_openBlock(), _createBlock(_component_nav_link, {
                    key: 1,
                    to: instagram,
                    target: "_blank",
                    class: "tv-show-hero__social-icon tv-show-hero__social-icon--instagram"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_instagram)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              tiktok
                ? (_openBlock(), _createBlock(_component_nav_link, {
                    key: 2,
                    to: tiktok,
                    target: "_blank",
                    class: "tv-show-hero__social-icon tv-show-hero__social-icon--tiktok"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_tiktok)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              twitter
                ? (_openBlock(), _createBlock(_component_nav_link, {
                    key: 3,
                    to: twitter,
                    target: "_blank",
                    class: "tv-show-hero__social-icon tv-show-hero__social-icon--twitter"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_twitter)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        (_ctx.extraBtnLabel && _ctx.extraBtnUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_nav_link, {
                to: _ctx.extraBtnUrl,
                class: "tv-show-hero__social-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.extraBtnLabel), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }))
}