import { reactive } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import { PAGE_ACTION, PAGE_NAMESPACE, PagePayload, PageResult } from "@rtl/ui";
import { Fetcher } from "@/utils";

@Options({})
export default class ContentPageFetcher extends Vue {
  contentPageFetcherInstance: Fetcher<PagePayload, PageResult> | null = null;

  get contentPage() {
    return this.contentPageFetcher.result?.data;
  }

  get contentPageFetcher() {
    if (!this.contentPageFetcherInstance) {
      this.contentPageFetcherInstance = reactive(
        new Fetcher((payload: PagePayload) => {
          return this.$store.dispatch(
            `${PAGE_NAMESPACE}/${PAGE_ACTION.ITEM}`,
            payload
          );
        })
      );
    }

    return this.contentPageFetcherInstance;
  }

  get contentPagePayload() {
    return {
      url: "",
    };
  }

  @Watch("contentPagePayload", { deep: true })
  async fetchContentPage() {
    return await this.contentPageFetcher.run(this.contentPagePayload);
  }

  serverPrefetch() {
    return this.fetchContentPage();
  }

  beforeMount() {
    return this.fetchContentPage();
  }
}
