import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_hero_widget_block = _resolveComponent("tv-show-hero-widget-block")!

  return (_openBlock(), _createBlock(_component_tv_show_hero_widget_block, {
    title: _ctx.site?.name,
    info: _ctx.info,
    "logo-url": _ctx.logo,
    "cover-img-url": _ctx.cover,
    "cta-btn-label": 'Teljes adások',
    "cta-btn-url": _ctx.fullBroadcasts ? _ctx.site?.rtlMostProgramUrl : null,
    "extra-btn-label": _ctx.extraBtnLabel,
    "extra-btn-url": _ctx.extraBtnUrl,
    menu: _ctx.menu
  }, {
    default: _withCtx(() => [
      (_ctx.site.parameters.config?.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.site.parameters.config.description), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.site.parameters.nmhhParams?.siteDescription && (_ctx.site.parameters.config.description !== _ctx.site.parameters.nmhhParams.siteDescription))
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.site.parameters.nmhhParams.siteDescription), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, [
        (_ctx.site.parameters.nmhhParams?.directorName)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, " Rendező: " + _toDisplayString(_ctx.site.parameters.nmhhParams.directorName), 1))
          : _createCommentVNode("", true),
        (_ctx.site.parameters.nmhhParams?.directorName && _ctx.site.parameters.nmhhParams.prodYear)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, " | "))
          : _createCommentVNode("", true),
        (_ctx.site.parameters.nmhhParams?.prodYear)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, " Gyártási év: " + _toDisplayString(_ctx.site.parameters.nmhhParams.prodYear), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "info", "logo-url", "cover-img-url", "cta-btn-url", "extra-btn-label", "extra-btn-url", "menu"]))
}